
















































import { DataTableHeader } from 'vuetify';
import JobApi from '@/services/api/JobApi';
import BaseList from '@/components/base/BaseList';
import { Component } from 'vue-property-decorator';
import Actions from '@/components/renderless/Actions';
import JobDialog from '@/components/jobs/JobDialog.vue';

@Component({
    components: {
        Actions,
        JobDialog
    }
})
export default class JobList extends BaseList {
    public apiClient = (new JobApi());
    public headers: DataTableHeader[] = [
        { text: '№', value: 'id' },
        { text: 'Title', value: 'name' },
        { text: 'Category', value: 'category.title' },
        { text: '', value: 'actions', align: 'end' }
    ];
}
